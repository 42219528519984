<template>
    <section>
        <div class="br-12 px-3 py-4 bg-white my-4 max-w-790px" style="box-shadow: 1px 1px 4px #00000014;">
            <div class="d-middle">
                <div class="text-general f-18 f-600">
                    Productos
                </div>
                <div class="ml-auto">
                    <label>Categorias</label>
                    <el-select v-model="idCategoria" placeholder="Todas" clearable size="medium" class="max-w-[165px] mx-2" @change="getSubCategorias">
                        <el-option
                        v-for="(c,k) in categorias"
                        :key="k"
                        :label="c.nombre"
                        :value="c.id"
                        />
                    </el-select>
                </div>
                <div class="">
                    <span>Sub Categorias</span>
                    <el-select v-model="idSubcategoria" placeholder="Todas" clearable size="medium" class="max-w-[165px] mx-2">
                        <el-option
                        v-for="(sc,k) in subCategorias"
                        :key="k"
                        :label="sc.nombre"
                        :value="sc.id"
                        />
                    </el-select>
                </div>
                <button class="btn btn-general h-36px w-100px mr-2" @click="getProductos">
                    Filtrar
                </button>
                <el-input v-model="search" prefix-icon="icon-search" placeholder="Buscar" size="medium" class="w-270px br-20" />
            </div>
            <div v-if="productosSeleccionados.length > 0" class="d-middle justify-content-end my-4 w-100">
                <div class="d-middle text-general mr-5">
                    <p class="f-18">{{ productosSeleccionados.length }} Seleccionados</p>
                    <i class="icon-close f-18 cr-pointer" />
                </div>
                <div class="d-middle">
                    <p class="f-18 text-general">Cambiar etiqueta</p>
                    <el-select v-model="idEtiqueta" placeholder="Seleccionar" size="medium" class="max-w-[165px] mx-2">
                        <el-option
                        v-for="(e,k) in etiquetas"
                        :key="k"
                        :label="e.texto"
                        :value="e.id"
                        />
                    </el-select>
                    <button class="btn btn-general h-36px w-100px mr-2" @click="setEtiqueta">
                        Cambiar
                    </button>
                </div>
            </div>
            <tabla-general
            :data="tableData"
            :columnas="dataColumnas"
            alto="calc(100vh - 284px)"
            :activar-seleccion-multiple="true"
            :activar-seleccion="true"
            :mostrar-buscador="false"
            :usar-paginacion="false"
            @seleccion="seleccionTabla"
            >
                <template v-slot:adicionales-derecha>
                    <el-table-column :label="`Etiqueta`" min-width="200" class-name="text-general f-600 f-16">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.idEtiqueta" placeholder="Seleccionar" size="medium" class="max-w-[165px] mx-2" @change="setEtiquetaProducto($event, scope.row.id)">
                                <el-option
                                v-for="(e,k) in etiquetas"
                                :key="k"
                                :label="e.texto"
                                :value="e.id"
                                />
                            </el-select>
                        </template>
                    </el-table-column>
                </template>
            </tabla-general>
        </div>
    </section>
</template>

<script>
import Service from '~/services/configurar/admin/descuentosLista';
export default {
    data(){
        return {
            search: '',
            productos: [],
            dataColumnas: [
                { valor: 'barcode', titulo:"BarCode", class: 'text-general f-16 ' },
                { valor: 'nombre', titulo:"Producto", class: 'text-general f-16 ' },
                { valor: 'presentacion', titulo:"Presentación", class: 'text-general f-16 ' },
            ],
            etiquetas:[],
            idEtiqueta:null,
            categorias:[],
            idCategoria:null,
            subCategorias:[],
            idSubcategoria:null,
            productosSeleccionados:[]
        }
    },
    computed:{
        tableData(){
            return this.productos.filter(
                data => !this.search || !data.barcode ||
                data.barcode.includes(this.search))
        }
    },
    mounted(){
        this.getCategorias();
        this.getEtiquetas();
        this.getProductos()
    },
    methods: {
        async getEtiquetas(){
            try {
                const {data} = await Service.getEtiquetas();
                this.etiquetas = data.etiquetas;
            } catch(e){
                this.error_catch(e);
            }
        },
        async getCategorias(){
            try {
                const {data} = await Service.getCategorias();
                this.categorias = data;
            } catch(e){
                this.error_catch(e);
            }
        },
        async getSubCategorias(){
            try {
                if(!this.idCategoria) return
                const {data} = await Service.getSubCategorias(this.idCategoria);
                this.idSubcategoria = null
                this.subCategorias = data.subcategorias;
            } catch(e){
                this.error_catch(e);
            }
        },
        async getProductos(){
            try {

                const query = {
                    idCategoria:this.idCategoria,
                    idSubCategoria:this.idSubcategoria
                }

                const {data} = await Service.getProductos(query);
                this.productos = data.productos;
            } catch(e){
                this.error_catch(e);
            }
        },
        async setEtiquetaProducto(idEtiqueta, idProducto){
            try {

                const payload = {
                    productos:[idProducto],
                    idEtiqueta
                }
                const {data} = await Service.updateProductoEtiqueta(payload);
                this.notificacion('Exito','Etiqueta actualizada','success')


            } catch (e){
                this.error_catch(e);
            }
        },
        async setEtiqueta(){
            try {

                if(this.productosSeleccionados.length == 0){
                    this.notificacion('Warning','Debes seleccionar almenos 1 producto','warning')
                    return
                }

                if(this.idEtiqueta == null || this.idEtiqueta == ''){
                    this.notificacion('Warning','Debes seleccionar la etiqueta','warning')
                    return
                }

                const payload = {
                    productos:this.productosSeleccionados,
                    idEtiqueta:this.idEtiqueta
                }
                const {data} = await Service.updateProductoEtiqueta(payload);
                this.idEtiqueta = null
                this.productosSeleccionados = []
                this.notificacion('Exito','Registros actualizados','success')
                await this.getProductos()

            } catch (e){
                this.error_catch(e);
            }
        },
        seleccionTabla(items){
            this.productosSeleccionados = _.map(items,'id')
        }

    }
}
</script>
